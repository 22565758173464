// {RpckApi.ts}
import axios from 'axios';
import {RpckVersionResponse, RpckUploadResponse} from 'quanterix-protos/shared/v1/rpck';

// Reverse proxy to localhost:{RPCK_PORT}/rpck
// Configured for local dev in vite.config.ts
// Configured for cloud deployment in Caddyfile
const rpckUpload = '/rpck/upload';
const rpckVersion = '/rpck/version';

export default async function UploadRpck(files: FileList | File[]) {
  const form = new FormData();
  form.append('rpck_file', files[0]);
  const response = await axios.post(rpckUpload, form, {responseType: 'blob'});
  
  const buffer = await response.data.arrayBuffer();
  const arr = new Uint8Array(buffer);

  const uploadResponse = RpckUploadResponse.decode(arr);
  
  return uploadResponse;
}

export async function GetRpckServiceVersion() {
  const response = await axios.get(rpckVersion, {responseType: 'blob'});
  const buffer = await response.data.arrayBuffer();
  const arr = new Uint8Array(buffer);
  const versionResponse = RpckVersionResponse.decode(arr);

  return versionResponse;
}

